








































@import '@design';

.page--header {
  position: relative;
  min-height: 60vh;
}
